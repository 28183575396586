import last from 'lodash/last'
import head from 'lodash/head'
import drop from 'lodash/drop'
import dropRight from 'lodash/dropRight'

export const getStylesCount = (items = {}) => {
  return Object.values(items)
    .filter(item => Array.isArray(item))
    .flat().length
}

export const getTypes = (items = {}) => {
  return Object.keys(items).filter(item => Array.isArray(items[item]) && Boolean(items[item].length))
}

export const nameByType = {
  1: 'Colors',
  2: 'Text',
  3: 'Effects',
  4: 'Grid'
}

export const mergeGroupedResults = (currentResults, results) => {
  const lastGroup = last(currentResults)
  const firstGroup = head(results)
  const [lastGroupName] = Object.keys(lastGroup)
  const [firstGroupName] = Object.keys(firstGroup)

  if (lastGroupName === firstGroupName) {
    const beforeMerged = dropRight(currentResults)
    const afterMerged = drop(results)
    const merged = {
      [lastGroupName]: [...lastGroup[lastGroupName], ...firstGroup[lastGroupName]]
    }

    return [...beforeMerged, merged, ...afterMerged]
  }

  return [...currentResults, ...results]
}

export const getItemName = (groupName, name) => {
  if (name) {
    return groupName === name ? name : name.replace(`${groupName}/ `, '')
  }

  return name
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "..."
  } else {
    return str
  }
}
