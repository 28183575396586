import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CollectionItems from './pages/collection-items'
import NotFound from './pages/404'
import './tailwind.output.css'
import { stylesPath, componentsPath } from './utils/url'

const Routes = () => {
  return (
    <Switch>
      <Route exact path={[stylesPath, componentsPath]} component={CollectionItems} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}

const App = () => {
  return (
    <React.StrictMode>
      <Router>
        <Routes />
      </Router>
    </React.StrictMode>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)

serviceWorker.unregister()
