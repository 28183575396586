import { useState, useEffect } from 'react'

const useCopy = () => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [copied])

  return [copied, setCopied]
}

export default useCopy

