import React from 'react'

const NotFound = () => {
  return (
    <div className="flex justify-center py-16 text-purple-800 w-full">
      <div className="min-h-full pt-16 pb-12 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">404 Not found</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn't find the collection you're looking for.</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default NotFound
