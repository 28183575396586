import React from 'react'
import { IconEffects } from '../../components/icons'
import copy from 'copy-to-clipboard'
import {
  normalizeCss as normalizeColorsCss,
  getHexTitle
} from '../../utils/style-colors'
import { getItemCss as getEffectsItemCss, getItemStyle as getEffectsStyle } from '../../utils/style-effects'
import { getItemCss as getTextItemCss } from '../../utils/style-text'
import CopyButton from './CopyButton'
import OpenButton from './OpenButton'
import Dropdown from './Dropdown'
import GridRender from './GridRender'
import useCopy from '../../utils/use-copy'

const BoxItem = ({ onItemClick, item, isActive }) => {
  const [copied, setCopied] = useCopy()

  return (
    <>
      {item.item_type === 1 && (
        <div
          className={`${isActive ? 'border-purple-400' : 'border-gray-200'} group absolute inset-0 w-full border`}
          style={{
            background: normalizeColorsCss(item.code.paints)
          }}
        >
          <>
            <button
              type="button"
              className={`${
                isActive ? 'border-purple-400' : 'border-transparent'
              } border absolute w-full inset-0 outline-none focus:outline-none`}
              onClick={onItemClick}
            >
              {getHexTitle(item.code.paints).isVisible && (
                <span className={`${getHexTitle(item.code.paints).isLight ? 'text-white' : 'text-black'} font-medium`}>
                  {getHexTitle(item.code.paints).hex}
                </span>
              )}
            </button>
            <Dropdown>
              <CopyButton
                copied={copied}
                onClick={() => {
                  copy(getHexTitle(item.code.paints).hex)
                  setCopied(true)
                }}
                disabled={copied}
                text="Copy Hex"
              />
              <OpenButton onClick={onItemClick} />
            </Dropdown>
          </>
        </div>
      )}

      {item.item_type === 2 && (
        <div className="group absolute inset-0 w-full border border-gray-200">
          <button
            type="button"
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } border absolute w-full inset-0 outline-none focus:outline-none`}
            onClick={onItemClick}
          >
            <span className="text-xl text-gray-700">{item.code.fontName.family.substring(0, 1)}</span>
          </button>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(getTextItemCss(item.code))
                setCopied(true)
              }}
              disabled={copied}
              text="Copy CSS"
            />
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
      {item.item_type === 3 && (
        <div className="group absolute inset-0">
          <button
            type="button"
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } rounded-full w-20 h-20 mx-auto absolute border border-gray-200 inset-0 outline-none focus:outline-none`}
            onClick={onItemClick}
            style={getEffectsStyle(item.code.effects)}
          >
            <IconEffects className="m-auto block" />
          </button>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(getEffectsItemCss(item.code.effects))
                setCopied(true)
              }}
              disabled={copied}
              text="Copy CSS"
            />
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
      {item.item_type === 4 && (
        <div
          className={`${
            isActive ? 'border-purple-400' : 'border-gray-200'
          } absolute group inset-0 w-full border rounded-2xl overflow-hidden`}
        >
          <button
            type="button"
            className="absolute w-full inset-0 outline-none focus:outline-none z-10"
            onClick={onItemClick}
          />
          <GridRender item={item} />
          <Dropdown>
            <OpenButton onClick={onItemClick} />
          </Dropdown>
        </div>
      )}
    </>
  )
}

export default BoxItem
