import React, { Suspense, lazy } from 'react'
import { CloudDownloadIcon } from '@heroicons/react/outline'


const s3Url = process.env.REACT_APP_S3_URL
const HtmlSyntaxHighlighter = lazy(() => import('../SyntaxHighlighter/HTMLHighlighter'))

const Component = ({ item, collection }) => {
  return (
    <>
      <div
        className="utility-code-inside inset-0 w-full items-center justify-center mb-4"
        dangerouslySetInnerHTML={{ __html: item.code }}
      />

      <button
        onClick={() => {
          window.open(`${s3Url}/c_${collection.id}/svg/${item.name}.svg`, '_blank').focus()
        }}
        className="flex items-center space-x-2 mb-4 group cursor-pointer select-none block"
      >
        <CloudDownloadIcon className="w-6 text-green-600 group-hover:text-green-700" />
        <span className="group-hover:text-gray-700">download svg</span>
      </button>
      <Suspense fallback={<span className="block text-xs text-center overflow-y-scroll">html compiling...</span>}>
        {item.code && <HtmlSyntaxHighlighter>{item.code}</HtmlSyntaxHighlighter>}
      </Suspense>
    </>
  )
}

export default Component
