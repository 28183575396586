import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { IconSearch } from '../icons'

const SearchForm = ({ onChange = () => {}, placeHolder }) => {
  const [searchQuery, setSearchQuery] = useState('')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(debounce((query) => onChange(query), 500), [])

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
      className="group sticky top-0 z-50 bg-white px-4 sm:px-6 lg:px-16 shadow"
    >
      <div className="max-w-container mx-auto flex">
        <label htmlFor="search-input" className="flex-none pr-3 flex items-center">
          <IconSearch className="text-gray-400 group-focus-within:text-gray-500 transition-colors duration-150" />
        </label>
        <input
          type="text"
          id="search-input"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value)
            delayedQuery(e.target.value)
          }}
          placeholder={placeHolder || 'Search'}
          className="flex-auto py-6 text-base leading-6 text-gray-500 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400"
          autoComplete="off"
        />
      </div>
    </form>
  )
}

export default SearchForm
