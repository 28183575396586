import React from 'react'

const Dropdown = ({ children }) => {
  return (
    <div className="top-16 w-28 absolute bg-white border border-1 border-gray-100 invisible group-hover:visible left-0 ml-auto mr-auto pb-3 pt-3 px-2 right-0 rounded-lg shadow-xl z-20 utility-dr-shadow">
      <div className="absolute h-0 left-0 mx-auto right-0 text-base text-white w-0 utility-arrow-up" />
      {children}
    </div>
  )
}

export default Dropdown
