import React, { useEffect, useState } from 'react'
import { collectionItemsGet } from '../../api/collections'
import { checkHttpStatus } from '../../utils/api'
import usePrevious from '../../utils/use-previous'
import Spinner from '../spinner'
import SearchForm from '../SearchForm'
import Panel from '../Panel'
import ComponentItem from '../ComponentItem'


const Components = ({ id, bg, componentsCount, collection }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [results, setResults] = useState([])
  const [total, setTotal] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showPanel, setShowPanel] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const prevSearchQuery = usePrevious(searchQuery)
  const pageSize = 300

  useEffect(() => {
    setError(null)
    if (id && (page || prevSearchQuery !== searchQuery)) {
      setLoading(true)
      collectionItemsGet({ id, searchQuery, types: 5, pageSize, page: page || 1, grouped: false })
        .then(checkHttpStatus)
        .then(data => {
          setResults(r => page > 1 ? [...r, ...data.results] : data.results)
          setHasMore(Boolean(data?.links?.next))
          setLoading(false)
          setTotal(data.total)
        })
        .catch(err => {
          setLoading(false)
          setError('Something went wrong')
        })
    }
  }, [id, searchQuery, prevSearchQuery, page])

  return (
    <>
      <SearchForm
        placeHolder={componentsCount > 1 && `Search all ${componentsCount} components`}
        onChange={data => [setPage(null), setSearchQuery(data), setTotal(null), setHasMore(false)]}
      />
      <div className={`${showPanel ? 'pl-4 sm:pl-6 lg:pl-16 sm:pr-80' : 'px-4 sm:px-6 lg:px-16'} flex-1 relative`}>
        <div className={`${showPanel ? 'pr-4' : ''} max-w-container mx-auto pb-12 pt-2 relative sm:pt-4`}>
          <section className="pt-4">
            {loading && page === 1 ? (
              <div className="w-full flex justify-center text-purple-700">
                <Spinner />
              </div>
            ) : (
              <>
                {results.length > 0 ? (
                  <ul className="
                    text-center 
                    items-start 
                    mb-8 
                    leading-4 
                    gap-2 
                    gap-x-8 
                    gap-y-4 
                    sm:gap-x-3 
                    sm:gap-y-8 
                    grid 
                    grid-cols-2 
                    md:grid-cols-4 
                    lg:grid-cols-6 
                    2xl:grid-cols-10"
                  >
                    {results.map(item => (
                      <ComponentItem
                        bg={bg}
                        item={item}
                        key={item.uuid}
                        onItemClick={(item) => [setShowPanel(true), setActiveItem(item)]}
                        activeItemId={activeItem?.id}
                        collection={collection}
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="max-w-3xl mx-auto text-center text-xl leading-9 font-medium text-gray-600 mt-10">No exported components.</div>
                )}
              </>
            )}

            {error && (
              <div className="w-full flex justify-center">
                <span className="text-gray-900">{error}</span>
              </div>
            )}

            <div className="text-center">
              {Boolean(total) && Boolean(results.length) && (
                <span className="block mb-5">{`showing ${results.length} out of ${total}`}</span>
              )}
              {hasMore && (
                <button
                  className="border border-purple-500 text-purple-500 hover:text-white hover:bg-purple-500 opacity-80 hover:opacity-100 outline-none focus:outline-none h-10 w-40 inline-flex items-center justify-center font-medium leading-none rounded-lg shadow-sm hover:shadow-lg px-5 duration-150"
                  type="button"
                  disabled={loading}
                  onClick={() => setPage((page || 1) + 1)}
                >
                  {loading ? <Spinner /> : <span>Load More</span>}
                </button>
              )}
            </div>
          </section>
        </div>

        {showPanel && activeItem && <Panel onClose={() => setShowPanel(false)} item={activeItem} style={`w-80 sm:w-1/2 lg:w-1/3`} collection={collection} />}
      </div>
    </>
  )
}

export default Components
