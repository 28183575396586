const rgb100toRgb255 = rgb => Math.round(rgb * 255)

const rgbAto100 = a => Math.round(a * 100) / 100

export const normalizeAlpha = value => `${rgbAto100(value || 0) * 100}%`

const getHexPart = rgb => rgb100toRgb255(rgb).toString(16).padStart(2, '0')

export const rgb2hex = color => {
  if (!color) return null
  const { r, g, b } = color

  const rHex = getHexPart(r)
  const gHex = getHexPart(g)
  const bHex = getHexPart(b)

  if (rHex.length > 2 || gHex.length > 2 || bHex.length > 2) return null

  return `${rHex}${gHex}${bHex}`
}

export const rgb2hexCss = color => {
  const hex = rgb2hex(color)
  if (!hex) return null

  return `#${hex}`
}

export const hasBorder = color => rgb2hex(color) === 'ffffff'

export const normalizeColor = color =>
  color.a === 1
    ? rgb2hexCss(color)
    : `rgba(${rgb100toRgb255(color.r)}, ${rgb100toRgb255(color.g)}, ${rgb100toRgb255(color.b)}, ${rgbAto100(color.a)})`

export const normalizeRgbString = paint =>
  `${rgb100toRgb255(paint.color.r)} ${rgb100toRgb255(paint.color.g)} ${rgb100toRgb255(paint.color.b)} ${
    paint.opacity !== 1 ? normalizeAlpha(paint.opacity) : ''
  }`

export const getRgbToCopy = ({ color, opacity }) =>
  opacity === 1
    ? `rgb(${rgb100toRgb255(color.r)}, ${rgb100toRgb255(color.g)}, ${rgb100toRgb255(color.b)})`
    : `rgba(${rgb100toRgb255(color.r)}, ${rgb100toRgb255(color.g)}, ${rgb100toRgb255(color.b)}, ${rgbAto100(opacity)})`
