import React, { useState, useEffect } from 'react'
import copy from 'copy-to-clipboard'
import CopyButton from '../BoxItem/CopyButton'
import Dropdown from '../BoxItem/Dropdown'
import useCopy from '../../utils/use-copy'
import { truncateString } from '../../utils/collection'


const ComponentItem = ({ onItemClick = () => {}, activeItemId, item, collection }) => {
  const [copied, setCopied] = useCopy()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (item.id === activeItemId) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [activeItemId])

  return (
    <li className="relative flex flex-col-reverse cursor-pointer">
      <h3>
        {item.meta_data?.pretty_name
        ?
          <>
            {truncateString(item.meta_data.pretty_name, 12)}
          </>
        :
          <>
            {truncateString(item.name, 12)}
          </>
        }
      </h3>
      <div className="relative mb-3 h-24 group">
        <div className={`${isActive ? 'border-purple-400' : 'border-gray-200'} group absolute inset-0 w-full border`}>
          <div
            className={`${
              isActive ? 'border-purple-400' : 'border-transparent'
            } border absolute w-full inset-0 outline-none focus:outline-none`}
            onClick={() => {
              onItemClick(item)
            }}
          >
            <div
              className="utility-code-inside p-4 absolute inset-0 w-full flex items-center justify-center"
              dangerouslySetInnerHTML={{ __html: item.code }}
            />
          </div>
          <Dropdown>
            <CopyButton
              copied={copied}
              onClick={() => {
                copy(item.code)
                setCopied(true)
              }}
              disabled={copied}
              text="Copy SVG"
            />
          </Dropdown>
        </div>
      </div>
    </li>
  )
}

export default ComponentItem
