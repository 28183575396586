const defaultFontStyle = 'normal'

const fontWeightByStyle = {
  'light': 300,
  'medium': 500,
  'regular': defaultFontStyle,
  'bold': 'bold'
}

const valueByUnit = {
  PERCENT: '%',
  PIXELS: 'px',
  AUTO: false,
}

const normalizeFontStyle = (style) => {
  const styleParse = style.toLowerCase().split(' ')

  return ({
    weight: fontWeightByStyle[styleParse[0]],
    style: styleParse[1] ? styleParse[1] : defaultFontStyle
  })
}

const normalizeLineHeight = (lineHeight) => {
  const units = valueByUnit[lineHeight.unit]
  const roundValue = Math.round((lineHeight.value || 0) * 100) / 100

  return lineHeight.value && units ? `${roundValue}${units}` : null
}

const normalizeFontSize = (code) => {
  return `${code.fontSize}px`
}

export const getItemList = (code) => {
  return [
    { name: 'Font', value: code.fontName.family },
    { name: 'Weight', value: normalizeFontStyle(code.fontName.style).weight },
    { name: 'Style', value: normalizeFontStyle(code.fontName.style).style },
    { name: 'Size', value: normalizeFontSize(code) },
    { name: 'Line Height', value: normalizeLineHeight(code.lineHeight) },
  ]
}

export const getItemCss = (code) => {
  return [
    `font-family: ${code.fontName.family};`,
    `font-style: ${normalizeFontStyle(code.fontName.style).style};`,
    `font-weight: ${normalizeFontStyle(code.fontName.style).weight};`,
    `font-size: ${normalizeFontSize(code)};`,
    normalizeLineHeight(code.lineHeight) && `line-height: ${normalizeLineHeight(code.lineHeight)};`,
  ].filter(Boolean).join('\n')
}
