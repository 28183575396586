import React from 'react'
import { IconExternal } from '../icons'

const OpenButton = ({ onClick = () => {} }) => {
  return (
    <button
      type="button"
      className="text-center px-4 text-xs py-1 w-full text-purple-500 border border-purple-500 hover:text-white hover:bg-purple-500 outline-none focus:outline-none font-semibold rounded-lg"
      onClick={onClick}
    >
      <IconExternal className="inline-block" />
    </button>
  )
}

export default OpenButton
