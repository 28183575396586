export const IconComponent = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="32" viewBox="0 0 32 32" width="32" className={className}>
      <path
        clipRule="evenodd"
        d="m12.0625 10.9375 3.9375 3.9375 3.9375-3.9375-3.9375-3.9375zm6.4608 0-2.5233 2.5233-2.5233-2.5233 2.5233-2.52329zm-6.4608 10.125 3.9375 3.9375 3.9375-3.9375-3.9375-3.9375zm6.4608 0-2.5233 2.5233-2.5233-2.5233 2.5233-2.5233zm-11.5233-5.0625 3.9375-3.9375 3.9375 3.9375-3.9375 3.9375zm3.9375 2.5233 2.5233-2.5233-2.5233-2.5233-2.52329 2.5233zm6.1875-2.5233 3.9375 3.9375 3.9375-3.9375-3.9375-3.9375zm6.4608 0-2.5233 2.5233-2.5233-2.5233 2.5233-2.5233z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const IconStyles = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="32" viewBox="0 0 32 32" width="32" className={className}>
      <g fill="currentColor">
        <path d="m11.5 13c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5z" />
        <path d="m17.5 13c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5z" />
        <path d="m19 20.5c-.8284 0-1.5-.6716-1.5-1.5s.6716-1.5 1.5-1.5 1.5.6716 1.5 1.5-.6716 1.5-1.5 1.5z" />
        <path d="m11.5 19c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5z" />
      </g>
    </svg>
  )
}

export const IconDownload = ({ className }) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={className}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
      ></path>
    </svg>
  )
}

export const IconCode = ({ className }) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={className}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
      ></path>
    </svg>
  )
}

export const IconSearch = ({ className }) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={className}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      ></path>
    </svg>
  )
}

export const IconClose = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="32" viewBox="0 0 32 32" width="32">
      <path
        d="m16 15.293 4.6465-4.6464.7071.7071-4.6465 4.6464 4.6465 4.6465-.7071.7071-4.6465-4.6464-4.6464 4.6464-.7071-.7071 4.6464-4.6465-4.6464-4.6463.7071-.7071z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconEffects = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
    >
      <g fill="currentColor">
        <path d="m16.5 8.5h-1v3h1z" />
        <path d="m11.0503 10.3431-.7071.7072 2.1213 2.1213.7071-.7071z" />
        <path d="m21.657 11.0503-.7071-.7071-2.1214 2.1213.7071.7071z" />
        <path d="m8.5 15.5v1h3v-1z" />
        <path d="m20.5 15.5v1h3v-1z" />
        <path d="m13.1716 19.5355-.7071-.7071-2.1213 2.1214.7071.7071z" />
        <path d="m19.5354 18.8284-.7071.7071 2.1213 2.1214.7071-.7071z" />
        <path d="m16.5 20.5h-1v3h1z" />
        <path
          clipRule="evenodd"
          d="m18.4978 15.9979c0 1.3807-1.1193 2.5-2.5 2.5s-2.5-1.1193-2.5-2.5 1.1193-2.5 2.5-2.5 2.5 1.1193 2.5 2.5zm-1 0c0 .8285-.6716 1.5-1.5 1.5s-1.5-.6715-1.5-1.5c0-.8284.6716-1.5 1.5-1.5s1.5.6716 1.5 1.5z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export const IconGridColumns = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
    >
      <g fill="currentColor">
        <path d="m9 9h3v14h-3z" />
        <path d="m14.5 9h3v14h-3z" />
        <path d="m20 9h3v14h-3z" />
      </g>
    </svg>
  )
}

export const IconGridRows = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
    >
      <g fill="currentColor">
        <path d="m9 9h14v3h-14z" />
        <path d="m9 14.5h14v3h-14z" />
        <path d="m9 20h14v3h-14z" />
      </g>
    </svg>
  )
}

export const IconGridUniform = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
    >
      <g fill="currentColor">
        <path d="m9 9h3v3h-3z" />
        <path d="m20 9h3v3h-3z" />
        <path d="m14.5 9h3v3h-3z" />
        <path d="m9 14.5h3v3h-3z" />
        <path d="m20 14.5h3v3h-3z" />
        <path d="m14.5 14.5h3v3h-3z" />
        <path d="m9 20h3v3h-3z" />
        <path d="m20 20h3v3h-3z" />
        <path d="m14.5 20h3v3h-3z" />
      </g>
    </svg>
  )
}

export const IconExternal = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M8.5 8.5H1.5V1.5H5V0.5H1.5C0.945 0.5 0.5 0.95 0.5 1.5V8.5C0.5 9.05 0.945 9.5 1.5 9.5H8.5C9.05 9.5 9.5 9.05 9.5 8.5V5H8.5V8.5ZM6 0.5V1.5H7.795L2.88 6.415L3.585 7.12L8.5 2.205V4H9.5V0.5H6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconArrowRight = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Logo = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <rect width="17" height="17" rx="3" fill="white" fillOpacity="0.7"/>
      <path 
        d="M15 13V6.82843C15 5.04662 12.8457 4.15428 11.5858 5.41421L5.41421 11.5858C4.15428 12.8457 5.04662 15 6.82843 15H13C14.1046 15 15 14.1046 15 13Z"
        fill="currentColor"
      />
    </svg>
  )
}

