// https://github.com/jongold/figma-js/blob/master/src/figmaTypes.ts

export const PaintTypeGraident = {
  IMAGE: 'image',
  SOLID: 'solid',
  GRADIENT_LINEAR: 'linear gradient',
  GRADIENT_RADIAL: 'radial gradient',
  GRADIENT_ANGULAR: 'angular gradient',
  GRADIENT_DIAMOND: 'diamond gradient'
}
