import { normalizeColor } from './style-base'

export const getNameByType = (type) => {
  if (!type) return null

  return type.toLowerCase().split('_').join(' ')
}

export const getItemStyle = effects => {
  if (!Boolean(effects.length)) return {}

  const filter = effects.find((item) => item.type === 'LAYER_BLUR')
  const backdropFilter = effects.find((item) => item.type === 'BACKGROUND_BLUR')
  const boxShadow = effects
    .filter(item => ['DROP_SHADOW', 'INNER_SHADOW'].includes(item.type))
    .map(
      item => `${item.type === 'INNER_SHADOW' ? 'inset ' : ''}${item.offset.x}px ${item.offset.y}px ${item.radius}px ${item.spread}px ${normalizeColor(item.color)}`
    )

  return ({
    filter: filter?.radius && `blur(${filter.radius}px)`,
    backdropFilter: backdropFilter?.radius && `blur(${backdropFilter.radius}px)`,
    boxShadow
  })
}

export const getItemCss = (effects) => {
  if (!Boolean(effects.length)) return null

  const { filter, backdropFilter, boxShadow } = getItemStyle(effects)

  return [
    Boolean(boxShadow.length) && `box-shadow: ${boxShadow.join(', ')};`,
    filter && `filter: ${filter};`,
    backdropFilter && `backdrop-filter: ${backdropFilter};`
  ].filter(Boolean).join('\n')
}
