import React from 'react'
import range from 'lodash/range'
import { normalizeColor } from '../../utils/style-base'

const styleByPattern = (layout) => {
  const patterns = {
    COLUMNS: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      minWidth: '100%',
      display: 'grid',
      gridAutoFlow: 'column',
      gridColumnGap: layout?.gutterSize,
      justifyContent:
        layout?.alignment === 'MAX'
          ? 'right'
          : layout?.alignment === 'MIN'
          ? 'left'
          : (layout?.alignment || '').toLowerCase(),
      gridTemplateColumns: layout?.sectionSize ? `repeat(${layout.count}, ${layout.sectionSize}px)` : 'auto',
      width: layout?.sectionSize ? '100%' : 'auto',
      paddingRight: layout?.offset,
      paddingLeft: layout?.offset
    },
    ROWS: {
      position: 'absolute',
      left: 0,
      right: 0,
      minHeight: '100%',
      display: 'grid',
      gridAutoFlow: 'row',
      gridRowGap: layout?.gutterSize,
      alignContent:
        layout?.alignment === 'MAX'
          ? 'end'
          : layout?.alignment === 'MIN'
          ? 'start'
          : (layout?.alignment || '').toLowerCase(),
      gridTemplateRows: layout?.sectionSize ? `repeat(${layout.count}, ${layout.sectionSize}px)` : 'auto',
      height: layout?.sectionSize ? '100%' : 'auto',
      paddingTop: layout?.offset,
      paddingBottom: layout?.offset
    },
    GRID: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundSize: `${layout.sectionSize}px ${layout.sectionSize}px`,
      backgroundImage: `linear-gradient(to right, ${normalizeColor(
        layout.color
      )} 1px, transparent 1px), linear-gradient(to bottom, ${normalizeColor(layout.color)} 1px, transparent 1px)`
    }
  }

  return patterns[layout.pattern]
}

const GridRender = ({ item }) => {
  return (
    <div className="absolute inset-0 w-full">
      {item.code.layoutGrids.map((layout, index) => (
        <div
          key={`${layout.pattern}-${index}`}
          style={styleByPattern(layout)}
        >
          {layout.count && range(layout.count).map(gridItem => (
            <div
              key={`${layout.pattern}-${index}-${gridItem}`}
              style={{ backgroundColor: normalizeColor(layout.color) }}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default GridRender
